import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.svg';
import appData from "../../Data/data.json";
import SegmentedBar from './SegmentedBar';

export default function StatementsNavigationBar() {

    return (
        <div className="statements-nav-bar">
            <Link to="/">
                <div className='logo-name'>
                    <img src={logo} className="logo" alt="logo" />
                    <h1 className='name'>SLYTALK</h1>
                </div>
            </Link>

            <SegmentedBar tabs={appData.statementsBar} />

        </div>
    );
}