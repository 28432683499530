import StatementContent from '../Components/Elements/StatementContent.js';
import NavigationBar from '../Components/Elements/StatementsNavigationBar.js'
import TableItem from '../Components/Elements/TableItem.js';
import appData from "../Data/data.json";

function Terms() {
    return (
        <div>
            <NavigationBar />
            <div className='statement-header'>

                <div className='table-of-content'>
                    <h2>Table of Content</h2>
                    {appData.termsOfUse.map((item) => (
                        <TableItem
                            id={item.id}
                            title={item.title}
                            key={item.id}
                        />
                    ))}
                </div>

                <div>
                    <h1>Terms of use</h1>
                    <body>Updated January 22, 2024</body>
                </div>
            </div>

            {appData.termsOfUse.map((item) => (
                <StatementContent
                    id={item.id}
                    title={item.title}
                    description={item.description}
                    key={item.id}
                />
            ))}
        </div>

    );
}

export default Terms;