import appstoreIcon from '../../../Assets/app-store-logo.svg';
import playstoreIcon from '../../../Assets/google-play-logo.svg';
import Chip from '../../Elements/Chip';
import ActivationButton from '../../Elements/ActivationButton';
import appData from '../../../Data/data.json';
import { motion } from "framer-motion"

export default function HomeHeader() {

    return (
        <div className='first-section' id='home-header'>
            <div className='description'>
                <h1>Hello,<br></br>it's time to talk</h1>
                <body>SLYTALK enables WhatsApp voice and video calls anywhere in the world.</body>
                <div className='stores'>

                    <a href="https://apps.apple.com/us/app/slytalk/id6468567861">
                        <Chip text="AppStore" icon={appstoreIcon} color="orange" />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.mobilesphere.slytalk">
                        <Chip text="Google Play" icon={playstoreIcon} color="grey" />
                    </a>
                </div>

            </div>
            <div className='container'>


                <div
                    className='graphics'
                >
                    {appData.messageBubbles.map((item) => (
                        <motion.div
                            className={`bubble ${item.color} ${item.side}`}
                            initial={{
                                opacity: 0,
                                marginTop: -100,
                                transition: { duration: 0 }
                            }}
                            whileInView={{
                                opacity: 0.8,
                                marginTop: 0,
                                transition: {
                                    duration: 0.5,
                                    delay: (item.id - 1) * 2,
                                    type: "spring"
                                }
                            }}
                        >
                            <p>{item.message}</p>
                        </motion.div>
                    ))}

                    <ActivationButton />
                </div>
            </div>
        </div >
    );
}