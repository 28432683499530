import { Link } from 'react-router-dom';
import Chip from '../../Components/Elements/Chip';

export default function Error() {
    return (
        <div className='error-section'>
            <h1>Error 404</h1>
            <h3>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h3>
            <Link to="/">
                <Chip color="orange" text="Back to home" icon="" />
            </Link>
        </div>
    );
}