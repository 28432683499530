import ExpandableBox from "../../Elements/ExpandableBox";
import appData from '../../../Data/data.json';

export default function FAQ() {
    return (
        <div className='fourth-section' id='faq'>
            <h1>FREQUENTLY ASKED QUESTIONS</h1>

            {appData.faqQuestions.map((item) => (
                <ExpandableBox
                    key={item.id}
                    number={item.id}
                    question={item.question}
                    answer={item.answer}
                />
            ))}
        </div>
    );
}