import HomeHeader from '../../Components/Home Sections/Mobile/HomeHeader';
import Mission from '../../Components/Home Sections/Mobile/Mission';
import Activation from '../../Components/Home Sections/Mobile/Activation';
import FAQ from '../../Components/Home Sections/Mobile/FAQ';
import Footer from '../../Components/Home Sections/Mobile/Footer';
import NavigationBar from '../../Components/Elements/NavigationBarMobile';

export default function HomeMobile() {
    return (
        <div>

            <NavigationBar />

            <div className='home'>

                <HomeHeader />

                <Mission />

                <Activation />

                <FAQ />

                <Footer />
            </div>
        </div>

    );
}