import { motion } from "framer-motion"

export default function PhoneBox(props) {
    return (

        <motion.div
            className={props.className}
            whileHover={{
                scale: 0.95
            }}
            transition={{
                duration: 0.5
            }}
        >
            <motion.div
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
                initial={{
                    opacity: 0.9,
                    scale: 0.9,
                    y: 100
                }}
                whileHover={{
                    opacity: 1,
                    scale: 1,
                    y: 0
                }}
                transition={{
                    duration: 0.5
                }}
            >
                <img className="phone" src={props.image} alt='' /></motion.div>
        </motion.div >
    );
}