
export default function ActivationDescription(props) {
    return (
        <div className='description'>
            <h2>{props.title}</h2>
            <body>{props.description}</body>
            <div className='stats'>
                <div className='item'>
                    <h2>{props.stat1Number}</h2>
                    <h4>{props.stat1Text}</h4>
                </div>

                <div className='item'>
                    <h2>{props.stat2Number}</h2>
                    <h4>{props.stat2Text}</h4>
                </div>
            </div>
        </div>
    );
}