import { motion } from "framer-motion"
import powerIcon from '../../Assets/power.svg';

export default function ActivationButton() {

    return (
        <div className="power-button-container">
            <motion.div
                className="power-button-ring"
                animate={{
                    scale: 3
                }}
                transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "mirror",
                    type: "spring"
                }}
            />
            <motion.div
                className="power-button-ring"
                animate={{
                    scale: 2.5
                }}
                transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "mirror",
                    type: "spring"
                }}
            />
            <motion.div
                className="power-button-ring"
                animate={{
                    scale: 2
                }}
                transition={{
                    repeat: Infinity,
                    duration: 2,
                    repeatType: "mirror",
                    type: "spring"
                }}
            />
            <div className="power-button">
                <img src={powerIcon} alt='Power icon' />
            </div>
        </div>
    );
}