import firstPhoneImage from '../../../Assets/phone-step-1.png';
import secondPhoneImage from '../../../Assets/phone-step-2.png';
import ActivationDescription from '../../Elements/ActivationDescription';
import PhoneBox from '../../Elements/PhoneBoxMobile';

export default function Activation() {
    return (
        <div className='third-section' id='activation'>
            <h1>Activation</h1>

            <div className='step'>

                <PhoneBox className='image orange' image={firstPhoneImage} />

                <ActivationDescription
                    title='Simple activation'
                    description='Activate SLYTALK by clicking on the activation button on the home screen.'
                    stat1Number={["100", <span>%</span>]}
                    stat1Text='Efficient'
                    stat2Number='1'
                    stat2Text='Required action'
                />
            </div>

            <div className='step'>

                <PhoneBox className='image grey' image={secondPhoneImage} />

                <ActivationDescription
                    title='We set up everything'
                    description='After activating SLYTALK, no additional steps are required. Just go to WhatsApp and call your friends and family as you usually do!'
                    stat1Number={["Infinite ", <span>#</span>]}
                    stat1Text='Of voice & video calls'
                />
            </div>
        </div>
    );
}