import { motion } from "framer-motion"
import React, { useState } from "react";
import plus from '../../Assets/plus.svg'

function ExpandableBox(props) {

    const [expanded, setExpanded] = useState(false);

    function handleExpansion() {
        setExpanded(!expanded)
    }

    return (
        <motion.div
            layout
            className="box"
        >
            <div className="header">
                <body className="number">{props.number}</body>
                <body className="question">{props.question}</body>
                <motion.div
                    animate={{
                        rotate: expanded ? 45 : 0
                    }}
                >
                    <img src={plus} alt='plus' onClick={handleExpansion} />
                </motion.div>

            </div>


            {expanded && (
                <body className='answer'>{props.answer}</body>
            )}

        </motion.div>
    );
}

export default ExpandableBox;