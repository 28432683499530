import React, { useState } from "react";
import { motion } from "framer-motion"
import emailIcon from '../../Assets/email.svg';
import planeIcon from '../../Assets/plane.svg';
import exitIcon from '../../Assets/exit.svg'
import TextInput from "../../Components/Elements/TextInput";
import subjectIcon from '../../Assets/subject.svg';
import Chip from "../../Components/Elements/Chip";
import LargeTextInput from "../../Components/Elements/LargeTextInput";
import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Modal({ onClose }) {

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    function sendEmail() {
        const mailgun = new Mailgun(FormData);
        const mg = mailgun.client({ username: 'api', key: process.env.REACT_APP_MAILGUN_API_KEY || '' });

        if (email === "" || subject === "" || message === "") {
            toast.error("Please fill all the fields before sending your message")
        } else {
            mg.messages.create(process.env.REACT_APP_MAILGUN_DOMAIN, {
                from: email,
                to: ['support@slytalk.me'],
                subject: subject,
                text: message
            })
                .then(msg => {
                    console.log(msg);
                    toast.success("Email sent successfully")
                    onClose()
                }) // logs response data
                .catch(err => {
                    console.log(err)
                    toast.error("An error occurred, please try again")
                }); // logs any error
        }
    }

    return (
        <motion.div
            className="modal"
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            transition={{ ease: "easeOut", duration: 0.4 }}
        >
            <motion.div
                className="modal-content"
                initial={{ y: "2000px" }}
                animate={{
                    opacity: 1,
                    y: "0",
                }}
                transition={{
                    ease: "easeOut",
                    duration: 0.8,
                    type: "spring"
                }}
            >
                <img src={exitIcon} alt="" onClick={onClose} />
                <h2>Contact us</h2>

                <TextInput name="email" placeholder="Enter your email" image={emailIcon} setInput={(e) => setEmail(e.target.value)} />

                <TextInput name="subject" placeholder="Enter the subject" image={subjectIcon} setInput={(e) => setSubject(e.target.value)} />

                <LargeTextInput name="message" placeholder="Enter your message" setInput={(e) => setMessage(e.target.value)} />

                <Chip color="orange" text="Send" icon={planeIcon} onClick={sendEmail} />

            </motion.div>

            <ToastContainer />
        </motion.div>
    );
}
