import { motion } from "framer-motion"

function Chip(props) {

    return (
        <motion.div
            className={`chip ${props.color}`}

            whileHover={{
                scale: 1.1,
            }}

            transition={{
                duration: 0.2
            }}

            onClick={props.onClick}
        >
            <p>{props.text}</p>
            {props.icon !== "" && (
                <img src={props.icon} alt='' />
            )}
        </motion.div>
    );
}

export default Chip;