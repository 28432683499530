import { Outlet } from "react-router-dom";
import StatementsNavigationBar from "../Components/Elements/StatementsNavigationBar";
import Footer from "../Components/Home Sections/Desktop/Footer";
import FooterMobile from "../Components/Home Sections/Mobile/Footer";
import useCheckMobileScreen from "../Helper/Utilities";

function Statements() {

    const isMobileDevice = useCheckMobileScreen();

    return (
        <div className="statements">
            <StatementsNavigationBar />

            <div className="content">
                <Outlet />
            </div>

            {isMobileDevice ? (
                <FooterMobile />
            ) : (
                <Footer />
            )}

        </div>

    );
}

export default Statements;