import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import React from 'react';

export default function SegmentedBar(props) {

    const [focused, setFocused] = React.useState(null);
    const [selected, setSelected] = React.useState(1);

    return (
        <div
            className="segmented-bar"
            onMouseLeave={() => setFocused(null)}>
            {props.tabs.map((item) => (
                <Link to={item.url}
                    className="tab"
                    key={item.id}
                    onClick={() => setSelected(item.id)}
                    onMouseEnter={() => setFocused(item.id)}
                    tabIndex={0}
                >
                    {item.title}

                    {focused === item.id ? (
                        <motion.div
                            className="highlight"
                            transition={{
                                layout: {
                                    duration: 0.5,
                                    ease: 'easeOut',
                                },
                            }}
                            layoutId="highlight"
                        />
                    ) : null}
                    {selected === item.id ? (
                        <motion.div
                            className="big-dot"
                            layoutId="underline"
                        />
                    ) : null}
                </Link>
            ))}
        </div>
    );
}