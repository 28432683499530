import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Desktop/Home';
import Statements from './Statements';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Error from './Desktop/Error';
import useCheckMobileScreen from '../Helper/Utilities';
import HomeMobile from './Mobile/HomeMobile';

function Main() {

    const isMobileDevice = useCheckMobileScreen();

    return (
        <Routes>
            <Route exact path='/' element={isMobileDevice ? <HomeMobile /> : <Home />} />
            <Route exact path='/statements' element={<Statements />}>
                <Route exact path='' element={<PrivacyPolicy />} />
                <Route exact path='privacy-policy' element={<PrivacyPolicy />} />
                <Route exact path='terms-of-use' element={<Terms />} />
            </Route>
            <Route exact path='*' element={<Error />}></Route>

        </Routes>
    );
}

export default Main;