function Dot(props) {

    if (props.size === "small") {
        return (
            <div className='small-dot' />
        );
    } else {
        return (
            <div className='big-dot' />
        );
    }
}

export default Dot;