import { motion } from "framer-motion"
import { useState } from "react";

export default function NavigationItem(props) {

    const [hover, setHover] = useState(false);

    return (
        <div className="item">
            <a className="text" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} href={props.href}>{props.name}</a>

            <motion.div
                className="bar"
                animate={{
                    scaleX: hover ? 1 : 0
                }}
                transition={{
                    duration: 0.2
                }}
            />
        </div>
    );
}