import { motion } from "framer-motion"
import { useEffect, useState } from "react";
import logo from '../../Assets/logo.svg';
import appData from '../../Data/data.json';
import NavigationItem from "./NavigationItem";

export default function NavigationBar() {

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY)
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <motion.div
            layout
            className='nav-bar'
            animate={{ backgroundColor: scrollPosition > 600 ? "rgba(249, 111, 46, 0.8)" : "rgba(0, 0, 0, 0.1)" }}
        >
            <a href="#home-header">
                <div className='logo-name'>
                    <img src={logo} className="logo" alt="logo" />
                    <h1 className='name'>SLYTALK</h1>
                </div>
            </a>

            <div className='nav-menu'>
                {appData.navigationBar.map((item) => (
                    <NavigationItem name={item.name} href={item.href} />
                ))}
            </div>
        </motion.div>
    );
}