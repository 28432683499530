import { Link } from "react-router-dom";
import { createPortal } from 'react-dom';
import React, { useState } from 'react'
import instagramLogo from '../../../Assets/instagram-logo.png';
import facebookLogo from '../../../Assets/facebook-logo.png';
import Dot from '../../Elements/Dot';
import Modal from "../../../Screens/Mobile/ModalMobile";

export default function Footer() {

    const [showModal, setShowModal] = useState(false);

    return (
        <div className='footer'>
            <div className='header'>
                <h1>SLYTALK</h1>
                <body>Enables your WhatsApp voice and video calls anywhere in the world</body>
            </div>

            {showModal && createPortal(
                <Modal onClose={() => setShowModal(false)} />,
                document.body
            )}

            <Link className="section" to='/' style={{ color: 'inherit', textDecoration: 'inherit' }}>Homepage</Link>
            <p className="section" onClick={() => setShowModal(true)}>Contact Us</p>

            <div className="social-media">
                <img src={instagramLogo} alt="Man video calling" />
                <img src={facebookLogo} alt="Man video calling" />
            </div>

            <div className="pages">
                <Link to='/statements/privacy-policy' style={{ color: 'inherit', textDecoration: 'inherit' }}>Privacy Policy</Link>
                <Dot size='small' />
                <Link to='/statements/terms-of-use' style={{ color: 'inherit', textDecoration: 'inherit' }}>Terms & Conditions</Link>
                <Dot size='small' />
                <a href="https://www2.mobile-sphere.com">MobileSphere</a>
            </div>
        </div>
    );
}