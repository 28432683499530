import Mission from '../../Components/Home Sections/Desktop/Mission.js'
import NavigationBar from '../../Components/Elements/NavigationBar.js'
import HomeHeader from '../../Components/Home Sections/Desktop/HomeHeader.js';
import Activation from '../../Components/Home Sections/Desktop/Activation.js';
import FAQ from '../../Components/Home Sections/Desktop/FAQ.js';
import Footer from '../../Components/Home Sections/Desktop/Footer.js';

export default function Home() {
    return (
        <div>
            <NavigationBar />

            <div className='home'>

                <HomeHeader />

                <Mission />

                <Activation />

                <FAQ />

                <Footer />
            </div>
        </div>

    );
}