import React, { useState } from "react";
import { motion } from "framer-motion"
import exitIcon from '../../Assets/exit.svg'
import appData from '../../Data/data.json';

export default function Sidebar({ onClose }) {

    return (
        <motion.div
            className="sidebar"
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            transition={{ ease: "easeOut", duration: 0.4 }}
        >
            <motion.div
                className="sidebar-content"
                initial={{
                    y: "200px",
                    opacity: 0
                }}
                animate={{
                    opacity: 1,
                    y: "0",
                }}
                transition={{
                    ease: "easeOut",
                    duration: 0.8,
                    type: "spring"
                }}
            >
                <img src={exitIcon} alt="" onClick={onClose} />
                <h2>SLYTALK</h2>

                <div className='sidebar-menu'>
                    {appData.navigationBar.map((item) => (
                        <a href={item.href} onClick={onClose}>{item.name}</a>
                    ))}
                </div>

            </motion.div>
        </motion.div>
    );
}