import whatsappLogo from '../../../Assets/whatsapp-logo.svg';
import Chip from '../../Elements/Chip.js'

export default function Mission() {
    return (
        <div className='second-section' id='mission'>
            <div className='first-column'>
                <div className='first-row'>
                    <Chip text='Our mission' icon="" color='orange' />
                    <Chip text='WhatsApp' icon={whatsappLogo} color='grey' />
                </div>

                <h1>We make voice an video calls accessible to everyone, everywhere.</h1>
            </div>

            <body>At SLYTALK, we are dedicated to breaking down communication barriers by providing unrestricted access to WhatsApp voice and video calls in regions where they are limited. We believe in the power of connection, and our mission is to empower individuals around the world to freely communicate, share, and connect with their loved ones, regardless of geographical constraints. </body>
        </div>
    );
}